import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import FirebaseContext from './firebase'
import { TicketSettingsUtils } from './TicketSettingsUtils'

const TicketSettingsContext = createContext<{
  ticketSettings?: TicketSettings
  utils?: TicketSettingsUtils
  updateTicketSettings: (newTicketSettings: TicketSettings) => void
  fetchTicketSettings: () => void
}>({
  updateTicketSettings: () => {},
  fetchTicketSettings: () => {},
})

export const TicketSettingsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const firebase = useContext(FirebaseContext)
  const [ticketSettings, setTicketSettings] = useState<TicketSettings>()

  const fetchTicketSettings = useCallback(() => {
    firebase?.getTicketSettings().then(setTicketSettings)
  }, [firebase])
  useEffect(() => fetchTicketSettings(), [firebase, fetchTicketSettings])

  const utils = useMemo(() => {
    return ticketSettings ? new TicketSettingsUtils(ticketSettings) : undefined
  }, [ticketSettings])

  const handleUpdateTicketSettings = (newTicketSettings: TicketSettings) => {
    firebase?.updateTicketSettings(newTicketSettings)
    setTicketSettings(newTicketSettings)
  }

  return (
    <TicketSettingsContext.Provider
      value={{
        ticketSettings,
        updateTicketSettings: handleUpdateTicketSettings,
        fetchTicketSettings,
        utils,
      }}
    >
      {children}
    </TicketSettingsContext.Provider>
  )
}

export const useTicketSettings = () => useContext(TicketSettingsContext)

import React, { useEffect, useRef, useState } from 'react'
import styles from './scss/layout.module.scss'
import FirebaseContext, {
  CustomerContext,
  Firebase,
  UserContextProvider,
} from './components/firebase'
import { TicketSettingsProvider } from './components/TicketSettingsContext'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useTheme, ThemeProvider, Link } from '@fluentui/react'
import { FIREBASE_CONFIG } from './constants'
import { DeviceProvider } from './components/DeviceContext'
import { PosSettingsProvider } from './components/PosSettingsContext'

const router = createBrowserRouter([
  {
    path: '/',
    lazy: async () => {
      const { default: Component } = await import(
        './components/routes/Booking/BookingWidget'
      )

      return { Component }
    },
  },
  {
    path: 'confirmation',
    lazy: async () => {
      const { default: Component } = await import(
        './components/routes/Confirmation/Confirmation'
      )

      return { Component }
    },
  },
  {
    path: 'customer-display',
    lazy: async () => {
      const { default: Component } = await import(
        './components/routes/Admin/CustomerDisplay/CustomerDisplayPage'
      )

      return { Component }
    },
  },
  {
    path: 'admin',
    lazy: async () => {
      const { default: Component } = await import(
        './components/routes/Admin/Admin'
      )

      return { Component }
    },
  },
  {
    path: 'admin/analytics',
    lazy: async () => {
      const { default: Component } = await import(
        './components/routes/Admin/analytics/AnalyticsPage'
      )

      return { Component }
    },
  },
  {
    path: 'admin/reports',
    lazy: async () => {
      const { default: Component } = await import(
        './components/routes/Admin/reports/ReportsPage'
      )

      return { Component }
    },
  },
])

function App() {
  const fluentUITheme = useTheme()
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--fluentui-accent-color',
      fluentUITheme.palette.accent,
    )
    document.documentElement.style.setProperty(
      '--fluentui-neutral-lighter',
      fluentUITheme.palette.neutralLighter,
    )
  }, [fluentUITheme])

  const [firebaseInstance, setFirebaseInstance] = useState<Firebase>()
  const firebaseInitStarted = useRef(false)
  useEffect(() => {
    if (firebaseInitStarted.current) {
      return
    }
    firebaseInitStarted.current = true

    if (process.env.REACT_APP_BOOKING_SYSTEM_ENV === 'development') {
      setFirebaseInstance(new Firebase(FIREBASE_CONFIG))
    } else {
      fetch('/__/firebase/init.json')
        .then(v => v.json())
        .then(result => {
          setFirebaseInstance(new Firebase(result))
        })
    }
  }, [])

  // Get user
  const [authUser, setAuthUser] = useState<AuthUser | null>(null)
  const [userData, setUserData] = useState<UserData | null>(null)
  const [userIsLoading, setUserIsLoading] = useState(true)

  useEffect(
    () =>
      firebaseInstance?.onAuthStateChanged((authUser, userData) => {
        setAuthUser(authUser)
        setUserData(userData)
        setUserIsLoading(false)
      }),
    [firebaseInstance],
  )

  // Get customer data
  const [customer, setCustomer] = useState<Customer>()
  useEffect(() => {
    firebaseInstance?.getCustomer().then(setCustomer)
  }, [firebaseInstance])

  if (!firebaseInstance) {
    return null
  }

  return (
    <FirebaseContext.Provider value={firebaseInstance}>
      <ThemeProvider>
        <UserContextProvider
          value={{ authUser, userData, isLoading: userIsLoading }}
        >
          <CustomerContext.Provider value={customer}>
            <TicketSettingsProvider>
              <PosSettingsProvider>
                <DeviceProvider>
                  <div className={styles.layout}>
                    <RouterProvider router={router} />
                    <div className={styles.spacer} />
                    <div className={styles.legal}>
                      <Link
                        href={customer?.legal?.impressumUrl}
                        target='_blank'
                      >
                        Impressum
                      </Link>
                      |
                      <Link href={customer?.legal?.privacyUrl} target='_blank'>
                        Datenschutz
                      </Link>
                    </div>
                  </div>
                </DeviceProvider>
              </PosSettingsProvider>
            </TicketSettingsProvider>
          </CustomerContext.Provider>
        </UserContextProvider>
      </ThemeProvider>
    </FirebaseContext.Provider>
  )
}

export default App

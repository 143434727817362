import React, { createContext, useContext } from 'react'
import { initializeApp } from 'firebase/app'
import type { FirebaseApp } from 'firebase/app'
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import type { Auth } from 'firebase/auth'
import {
  addDoc,
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  setDoc,
  where,
} from 'firebase/firestore'
import type {
  CollectionReference,
  DocumentData,
  Firestore,
  Query,
} from 'firebase/firestore'
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions'
import type { Functions } from 'firebase/functions'
import { endOfDay, format, startOfDay } from 'date-fns'

export class Firebase {
  app: FirebaseApp
  auth: Auth
  db: Firestore
  functions: Functions

  bookTicket: (
    data: Endpoints['bookTicket']['request'],
  ) => Promise<Endpoints['bookTicket']['response']>

  bookContingentTicket: (
    data: Endpoints['bookContingentTicket']['request'],
  ) => Promise<Endpoints['bookContingentTicket']['response']>

  createBooking: (
    data: Endpoints['createBooking']['request'],
  ) => Promise<Endpoints['createBooking']['response']>

  createContingentTicket: (
    data: Endpoints['createContingentTicket']['request'],
  ) => Promise<Endpoints['createContingentTicket']['response']>

  createPosBookings: (
    data: Endpoints['createPosBookings']['request'],
  ) => Promise<Endpoints['createPosBookings']['response']>

  createOnSiteBooking: (
    data: Endpoints['createOnSiteBooking']['request'],
  ) => Promise<Endpoints['createOnSiteBooking']['response']>

  resendContingentTicketEmail: (
    data: Endpoints['resendContingentTicketEmail']['request'],
  ) => Promise<Endpoints['resendContingentTicketEmail']['response']>

  tx: {
    setAdminPin: (
      data: Endpoints['tx']['setAdminPin']['request'],
    ) => Promise<Endpoints['tx']['setAdminPin']['response']>
    authenticateAdmin: (
      data: Endpoints['tx']['authenticateAdmin']['request'],
    ) => Promise<Endpoints['tx']['authenticateAdmin']['response']>
    logoutAdmin: (
      data: Endpoints['tx']['logoutAdmin']['request'],
    ) => Promise<Endpoints['tx']['logoutAdmin']['response']>
    createTss: () => Promise<Endpoints['tx']['createTss']['response']>
    getTssList: () => Promise<Endpoints['tx']['getTssList']['response']>
    updateTss: (
      data: Endpoints['tx']['updateTss']['request'],
    ) => Promise<Endpoints['tx']['updateTss']['response']>
    createClient: (
      data: Endpoints['tx']['createClient']['request'],
    ) => Promise<Endpoints['tx']['createClient']['response']>
    getClients: () => Promise<Endpoints['tx']['getClients']['response']>
    getClientsByTss: (
      data: Endpoints['tx']['getClientsByTss']['request'],
    ) => Promise<Endpoints['tx']['getClientsByTss']['response']>
    startTransaction: (
      data: Endpoints['tx']['startTransaction']['request'],
    ) => Promise<Endpoints['tx']['startTransaction']['response']>
    cancelTransaction: (
      data: Endpoints['tx']['cancelTransaction']['request'],
    ) => Promise<Endpoints['tx']['cancelTransaction']['response']>
    finishTransaction: (
      data: Endpoints['tx']['finishTransaction']['request'],
    ) => Promise<Endpoints['tx']['finishTransaction']['response']>
    getTransactionQrCode: (
      data: Endpoints['tx']['getTransactionQrCode']['request'],
    ) => Promise<Endpoints['tx']['getTransactionQrCode']['response']>
  }

  dsfinvk: {
    getVatDefinitions: () => Promise<
      Endpoints['dsfinvk']['getVatDefinitions']['response']
    >
    createCashRegister: (
      data: Endpoints['dsfinvk']['createCashRegister']['request'],
    ) => Promise<Endpoints['dsfinvk']['createCashRegister']['response']>
    getCashRegisters: () => Promise<
      Endpoints['dsfinvk']['getCashRegisters']['response']
    >
    insertCashPointClosing: (
      data: Endpoints['dsfinvk']['insertCashPointClosing']['request'],
    ) => Promise<Endpoints['dsfinvk']['insertCashPointClosing']['response']>
    manuallyInsertCashPointClosing: (
      data: Endpoints['dsfinvk']['manuallyInsertCashPointClosing']['request'],
    ) => Promise<
      Endpoints['dsfinvk']['manuallyInsertCashPointClosing']['response']
    >
    getCashPointClosings: (
      data: Endpoints['dsfinvk']['getCashPointClosings']['request'],
    ) => Promise<Endpoints['dsfinvk']['getCashPointClosings']['response']>
    getCashPointClosingDetails: (
      data: Endpoints['dsfinvk']['getCashPointClosingDetails']['request'],
    ) => Promise<Endpoints['dsfinvk']['getCashPointClosingDetails']['response']>
    getCashPointClosingDetailsPosSales: (
      data: Endpoints['dsfinvk']['getCashPointClosingDetailsPosSales']['request'],
    ) => Promise<
      Endpoints['dsfinvk']['getCashPointClosingDetailsPosSales']['response']
    >
  }

  constructor(firebaseConfig: FirebaseConfig) {
    this.app = initializeApp(firebaseConfig)

    this.auth = getAuth(this.app)
    this.db = getFirestore(this.app)
    this.functions = getFunctions(this.app)

    if (process.env.REACT_APP_BOOKING_SYSTEM_ENV === 'development') {
      connectFunctionsEmulator(this.functions, window.location.hostname, 5001)
      connectFirestoreEmulator(this.db, window.location.hostname, 8080)
    }

    this.bookTicket = this.createHttpsCallable('bookTicket')
    this.bookContingentTicket = this.createHttpsCallable('bookContingentTicket')
    this.createBooking = this.createHttpsCallable('createBooking')
    this.createContingentTicket = this.createHttpsCallable(
      'createContingentTicket',
    )
    this.createPosBookings = this.createHttpsCallable('createPosBookings')
    this.createOnSiteBooking = this.createHttpsCallable('createOnSiteBooking')
    this.resendContingentTicketEmail = this.createHttpsCallable(
      'resendContingentTicketEmail',
    )

    this.tx = {
      setAdminPin: this.createHttpsCallable('tx-setAdminPin'),
      authenticateAdmin: this.createHttpsCallable('tx-authenticateAdmin'),
      logoutAdmin: this.createHttpsCallable('tx-logoutAdmin'),
      createTss: this.createHttpsCallable('tx-createTss'),
      getTssList: this.createHttpsCallable('tx-getTssList'),
      updateTss: this.createHttpsCallable('tx-updateTss'),
      createClient: this.createHttpsCallable('tx-createClient'),
      getClients: this.createHttpsCallable('tx-getClients'),
      getClientsByTss: this.createHttpsCallable('tx-getClientsByTss'),
      startTransaction: this.createHttpsCallable('tx-startTransaction'),
      cancelTransaction: this.createHttpsCallable('tx-cancelTransaction'),
      finishTransaction: this.createHttpsCallable('tx-finishTransaction'),
      getTransactionQrCode: this.createHttpsCallable('tx-getTransactionQrCode'),
    }

    this.dsfinvk = {
      getVatDefinitions: this.createHttpsCallable('dsfinvk-getVatDefinitions'),
      createCashRegister: this.createHttpsCallable(
        'dsfinvk-createCashRegister',
      ),
      getCashRegisters: this.createHttpsCallable('dsfinvk-getCashRegisters'),
      insertCashPointClosing: this.createHttpsCallable(
        'dsfinvk-insertCashPointClosing',
      ),
      manuallyInsertCashPointClosing: this.createHttpsCallable(
        'dsfinvk-manuallyInsertCashPointClosing',
      ),
      getCashPointClosings: this.createHttpsCallable(
        'dsfinvk-getCashPointClosings',
      ),
      getCashPointClosingDetails: this.createHttpsCallable(
        'dsfinvk-getCashPointClosingDetails',
      ),
      getCashPointClosingDetailsPosSales: this.createHttpsCallable(
        'dsfinvk-getCashPointClosingDetailsPosSales',
      ),
    }
  }

  createHttpsCallable = (name: string): ((data?: any) => Promise<any>) => {
    const callable = httpsCallable(this.functions, name)
    return async (data?: any) => (await callable(data)).data
  }

  subscribeToCollection = (
    collectionName: string,
    callback: (data: any[]) => void,
  ) => {
    const collectionRef = collection(this.db, collectionName)
    return onSnapshot(collectionRef, querySnapshot => {
      callback(
        querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })),
      )
    })
  }

  subscribeToDocumentData = (
    collectionName: string,
    document: string,
    callback: (data: any) => void,
    withId?: boolean,
  ) => {
    const docRef = doc(this.db, collectionName, document)
    return onSnapshot(docRef, doc => {
      const data = doc.data()
      callback(withId ? { ...data, id: doc.id } : data)
    })
  }

  setDocument = (collectionName: string, documentId: string, data: any) => {
    const docRef = doc(this.db, collectionName, documentId)
    return setDoc(docRef, data)
  }

  doSignIn = (email: string, password: string) =>
    signInWithEmailAndPassword(this.auth, email, password)

  doSignOut = () => signOut(this.auth)

  onAuthStateChanged = (
    callback: (authUser: AuthUser | null, userData: UserData | null) => void,
  ) =>
    onAuthStateChanged(this.auth, async authUser => {
      if (authUser) {
        const userDocRef = doc(this.db, 'users', authUser.uid)
        const userDocSnap = await getDoc(userDocRef)
        const userData = userDocSnap.exists() ? userDocSnap.data() : null
        callback(authUser, userData)
      } else {
        callback(null, null)
      }
    })

  getGroupCapacityForDate = async (
    date: Date,
  ): Promise<DateGroupCapacity | undefined> => {
    const docRef = doc(this.db, 'groupCapacities', format(date, 'yyyy-MM-dd'))
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  }

  getTicketSettings = async (): Promise<TicketSettings | undefined> => {
    const docRef = doc(this.db, 'settings', 'tickets')
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  }

  updateTicketSettings = (newSettings: TicketSettings) => {
    const docRef = doc(this.db, 'settings', 'tickets')
    return setDoc(docRef, newSettings)
  }

  updateSlotCapacity = (
    slotId: string,
    capacityGroups: {
      id: string
      capacity: number
    }[],
  ) => {
    const ticketSettingsDocRef = doc(this.db, 'settings', 'tickets')
    return runTransaction(this.db, async transaction => {
      const ticketSettingsDocSnap = await transaction.get(ticketSettingsDocRef)
      const ticketSettings: TicketSettings = ticketSettingsDocSnap.data() || {}

      const slot = ticketSettings.slots?.find(s => s.id === slotId)

      if (!slot) return

      capacityGroups.forEach(({ id, capacity }) => {
        const group = slot.capacityGroups?.find(g => g.id === id)

        if (!group) return

        group.capacity = capacity
      })

      transaction.set(ticketSettingsDocRef, ticketSettings)
    })
  }

  subscribeToBookings = (date: Date, callback: (data: Booking[]) => void) => {
    const collectionRef = collection(this.db, 'bookings')
    const q = query(collectionRef, where('date', '==', date))
    return onSnapshot(q, querySnapshot => {
      callback(
        querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })),
      )
    })
  }

  subscribeToContingentTicketsForTable = (
    includeInvalidTickets: boolean,
    callback: (data: ContingentTicket[]) => void,
  ) => {
    const collectionRef = collection(this.db, 'contingentTickets')
    let ref: CollectionReference<DocumentData> | Query<DocumentData> =
      collectionRef

    if (!includeInvalidTickets) {
      ref = query(
        collectionRef,
        where('payment.status', '==', 'paid'),
        where('validUntil', '>=', new Date()),
      )
    }

    return onSnapshot(ref, querySnapshot => {
      callback(
        querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })),
      )
    })
  }

  subscribeToContingentTicketsBySlotIds = (
    date: Date,
    slotIds: string[],
    callback: (data: ContingentTicket[]) => void,
  ) => {
    const collectionRef = collection(this.db, 'contingentTickets')
    const isoDate = format(date, 'yyyy-MM-dd')
    const q = query(
      collectionRef,
      where(
        'checkedIn',
        'array-contains-any',
        slotIds.map(slotId => ({ date: isoDate, slotId })),
      ),
    )
    return onSnapshot(q, querySnapshot => {
      callback(
        querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })),
      )
    })
  }

  doCheckIn = (bookingId: string) => {
    const docRef = doc(this.db, 'bookings', bookingId)
    return setDoc(
      docRef,
      {
        checkedIn: new Date(),
      },
      { merge: true },
    )
  }

  doCheckInContingentTicket = (
    contingentTicketId: string,
    isoDate: string,
    slotId: string,
    capacityGroupId: string,
  ) => {
    const ticketDocRef = doc(this.db, 'contingentTickets', contingentTicketId)
    const groupCapacityRef = doc(this.db, 'groupCapacities', isoDate)
    return runTransaction(this.db, async transaction => {
      const ticketDocSnap = await transaction.get(ticketDocRef)
      const checkedIn = ticketDocSnap.get('checkedIn') || []

      checkedIn.push({ date: isoDate, slotId })

      const groupCapacityDocSnap = await transaction.get(groupCapacityRef)
      const groupCapacity: DateGroupCapacity = groupCapacityDocSnap.data() || {}

      if (!groupCapacity[capacityGroupId]) {
        groupCapacity[capacityGroupId] = 0
      }

      groupCapacity[capacityGroupId] += 1

      transaction.update(ticketDocRef, { checkedIn })
      transaction.set(groupCapacityRef, groupCapacity)
    })
  }

  doCheckInExternalTicket = async (
    capacityGroupId: string,
    isoDate: string,
  ) => {
    const groupCapacityRef = doc(this.db, 'groupCapacities', isoDate)
    const externalCheckInsRef = doc(this.db, 'externalCheckIns', isoDate)
    return runTransaction(this.db, async transaction => {
      const groupCapacityDocSnap = await transaction.get(groupCapacityRef)
      const groupCapacity: DateGroupCapacity = groupCapacityDocSnap.data() || {}

      if (!groupCapacity[capacityGroupId]) {
        groupCapacity[capacityGroupId] = 0
      }

      groupCapacity[capacityGroupId] += 1

      const externalCheckinsDocSnap = await transaction.get(externalCheckInsRef)
      const externalCheckins: ExternalCheckIns =
        externalCheckinsDocSnap.data() || {}

      if (!externalCheckins[capacityGroupId]) {
        externalCheckins[capacityGroupId] = 0
      }

      externalCheckins[capacityGroupId] += 1

      transaction.set(groupCapacityRef, groupCapacity)
      transaction.set(externalCheckInsRef, externalCheckins)
    })
  }

  changeContingentTicketName = (ticketId: string, name: string) => {
    const docRef = doc(this.db, 'contingentTickets', ticketId)
    return setDoc(docRef, { name }, { merge: true })
  }

  changeContingentTicketEmail = (ticketId: string, email: string) => {
    const docRef = doc(this.db, 'contingentTickets', ticketId)
    return setDoc(docRef, { email }, { merge: true })
  }

  invalidateContingentTicket = (ticketId: string) => {
    const docRef = doc(this.db, 'contingentTickets', ticketId)
    return setDoc(docRef, { invalidated: new Date() }, { merge: true })
  }

  getBookingById = async (bookingId: string): Promise<Booking | undefined> => {
    const docRef = doc(this.db, 'bookings', bookingId)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  }

  getContingentTicketById = async (
    bookingId: string,
  ): Promise<ContingentTicket | undefined> => {
    const docRef = doc(this.db, 'contingentTickets', bookingId)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  }

  getCustomer = async (): Promise<Customer | undefined> => {
    const docRef = doc(this.db, 'settings', 'customer')
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  }

  updateCustomer = (customerData: Customer) => {
    const docRef = doc(this.db, 'settings', 'customer')
    return setDoc(docRef, customerData)
  }

  getBookingsForAnalytics = async (
    from: Date,
    to: Date,
  ): Promise<Booking[]> => {
    const collectionRef = collection(this.db, 'bookings')
    const q = query(
      collectionRef,
      where('date', '>=', from),
      where('date', '<=', to),
      where('payment.status', 'in', ['paid', 'reserved']),
    )
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }))
  }

  getContingentTicketsForAnalytics = async (): Promise<any> => {
    const collectionRef = collection(this.db, 'contingentTickets')
    const q = query(collectionRef, where('payment.status', '==', 'paid'))
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }))
  }

  getExternalCheckInsForAnalytics = async (
    from: Date,
    to: Date,
  ): Promise<{
    [isoDate: string]: ExternalCheckIns
  }> => {
    const collectionRef = collection(this.db, 'externalCheckIns')
    const fromIso = format(from, 'yyyy-MM-dd')
    const toIso = format(to, 'yyyy-MM-dd')
    const q = query(
      collectionRef,
      where('__name__', '>=', fromIso),
      where('__name__', '<=', toIso),
    )
    const querySnapshot = await getDocs(q)
    return Object.fromEntries(
      querySnapshot.docs.map(doc => [doc.id, doc.data()]),
    )
  }

  getSoldTicketsForAnalytics = async (
    from: Date,
    to: Date,
  ): Promise<{
    bookings: Booking[]
    contingentTickets: ContingentTicket[]
  }> => {
    const fromTime = startOfDay(from)
    const toTime = endOfDay(to)

    const getBookings = async () => {
      const collectionRef = collection(this.db, 'bookings')
      const q = query(
        collectionRef,
        where('createdAt', '>=', fromTime),
        where('createdAt', '<=', toTime),
        where('payment.status', '==', 'paid'),
      )
      const querySnapshot = await getDocs(q)
      return querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }))
    }

    const getContingentTickets = async () => {
      const collectionRef = collection(this.db, 'contingentTickets')
      const q = query(
        collectionRef,
        where('createdAt', '>=', fromTime),
        where('createdAt', '<=', toTime),
        where('payment.status', '==', 'paid'),
      )
      const querySnapshot = await getDocs(q)
      return querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }))
    }

    const [bookings, contingentTickets] = await Promise.all([
      getBookings(),
      getContingentTickets(),
    ])

    return {
      bookings,
      contingentTickets,
    }
  }

  createCashRegister = async (cashRegister: CashRegister) => {
    const collectionRef = collection(this.db, 'cashRegisters')
    const docRef = await addDoc(collectionRef, cashRegister)
    return docRef.id
  }

  updateCashRegister = async (
    cashRegisterId: string,
    cashRegister: Partial<CashRegister>,
  ) => {
    const docRef = doc(this.db, 'cashRegisters', cashRegisterId)
    return setDoc(docRef, cashRegister, { merge: true })
  }

  subscribeToCashRegister = (
    deviceId: string,
    callback: (
      cashRegister: (CashRegister & { id: string }) | undefined,
    ) => void,
  ) => {
    if (!deviceId) {
      return
    }

    const collectionRef = collection(this.db, 'cashRegisters')
    const q = query(collectionRef, where('deviceId', '==', deviceId))
    return onSnapshot(q, querySnapshot => {
      if (querySnapshot.docs.length === 0) {
        callback(undefined)
        return
      }

      callback({
        id: querySnapshot.docs[0]!.id,
        ...(querySnapshot.docs[0]!.data() as CashRegister),
      })
    })
  }

  updateCustomerDisplay = async (
    customerDisplayId: string,
    data: Partial<CustomerDisplay>,
  ) => {
    const docRef = doc(this.db, 'customerDisplays', customerDisplayId)
    return setDoc(docRef, data, { merge: true })
  }

  subscribeToCustomerDisplay = (
    deviceId: string,
    callback: (
      customerDisplay: (CustomerDisplay & { id: string }) | undefined,
    ) => void,
  ) => {
    if (!deviceId) {
      return
    }

    const collectionRef = collection(this.db, 'customerDisplays')
    const q = query(collectionRef, where('deviceId', '==', deviceId))
    return onSnapshot(q, querySnapshot => {
      if (querySnapshot.docs.length === 0) {
        callback(undefined)
        return
      }

      callback({
        id: querySnapshot.docs[0]!.id,
        ...(querySnapshot.docs[0]!.data() as CustomerDisplay),
      })
    })
  }

  async getPosSalesSinceOpening(clientId: string) {
    const collectionRef = collection(this.db, 'posSales')
    const q = query(
      collectionRef,
      where('clientId', '==', clientId),
      where('cashPointClosingId', '==', null),
      orderBy('date', 'desc'),
    )
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map(doc => doc.data()) as PosSale[]
  }
}

export const CustomerContext = createContext<Customer | undefined>(undefined)

const FirebaseContext = React.createContext<Firebase>({} as Firebase)

export default FirebaseContext

const UserContext = createContext<{
  authUser: AuthUser | null
  userData: UserData | null
  isLoading: boolean
}>({
  authUser: null,
  userData: null,
  isLoading: true,
})
export const useUser = () => useContext(UserContext)
export const UserContextProvider = UserContext.Provider

import { createContext, useContext, useEffect, useState } from 'react'
import FirebaseContext from './firebase'

const DeviceContext = createContext<{
  deviceId: string
  setDeviceId: (deviceId: string) => void
  cashRegisterData: (CashRegister & { id: string }) | undefined
}>({
  deviceId: '',
  setDeviceId: () => {},
  cashRegisterData: undefined,
})

const LocalStorageKey = 'bookinstantDeviceId'

const getDeviceIdFromLocalStorage = () => {
  try {
    return localStorage.getItem(LocalStorageKey) || ''
  } catch (error) {
    return ''
  }
}

const saveDeviceIdToLocalStorage = (deviceId: string) => {
  try {
    localStorage.setItem(LocalStorageKey, deviceId)
  } catch (error) {}
}

export const DeviceProvider = ({ children }: { children: React.ReactNode }) => {
  const [deviceId, setDeviceIdState] = useState(getDeviceIdFromLocalStorage)

  const setDeviceId = (deviceId: string) => {
    setDeviceIdState(deviceId)
    saveDeviceIdToLocalStorage(deviceId)
  }

  const [cashRegisterData, setCashRegisterData] = useState<
    CashRegister & { id: string }
  >()
  const firebase = useContext(FirebaseContext)
  useEffect(() => {
    return firebase.subscribeToCashRegister(deviceId, setCashRegisterData)
  }, [deviceId, firebase])

  return (
    <DeviceContext.Provider
      value={{
        deviceId,
        setDeviceId,
        cashRegisterData,
      }}
    >
      {children}
    </DeviceContext.Provider>
  )
}

export const useDevice = () => useContext(DeviceContext)

import { de } from 'date-fns/locale'

export const toDecimals = (num: number, decimals = 2) => {
  const factor = 10 ** decimals
  return (Math.round(num * factor) / factor).toFixed(decimals).replace('.', ',')
}

export const getPaymentStatusLabel = (
  payment:
    | {
        status: PaymentStatusType
        onSite?: boolean
        posSaleId?: string
        adminCreated?: boolean
      }
    | undefined,
) => {
  if (!payment) {
    return ''
  }

  const statusLabel = {
    pending: 'Zahlung ausstehend',
    paid: 'Bezahlt',
    failed: 'Zahlung fehlgeschlagen',
    reserved: 'Reserviert',
  }[payment.status]

  let suffix = ''
  if (payment.onSite) {
    suffix = ' (Vor Ort)'
  }

  if (payment.posSaleId) {
    suffix = ` (Kasse)`
  }

  if (payment.adminCreated) {
    suffix = ` (Ersatzticket)`
  }

  return statusLabel + suffix
}

export const toUtcDateHoursZeroed = (date: Date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}

export const toIsoDate = (date: Date) => {
  return toUtcDateHoursZeroed(date).toISOString().split('T')[0]!
}

export const getWeekDay = (date: Date) => {
  let weekDay = date.getDay()
  // Map weekDay to Mon - Sun, as JavaScript dates are mapped to Sun - Sat
  weekDay--
  if (weekDay < 0) {
    weekDay = 6
  }

  return weekDay
}

export const formatDuration = (start: Date, end: Date) => {
  const durationInMinutes = (end.getTime() - start.getTime()) / 1000 / 60
  const hours = Math.floor(durationInMinutes / 60)
  const minutes = durationInMinutes % 60

  return `${hours.toFixed()} Std. ${minutes.toFixed()} Min.`
}

export const WeekdayLabels: string[] = Array.from(Array(7)).map((_, i) =>
  de.localize?.day(i, { width: 'wide' }),
)
WeekdayLabels.push(WeekdayLabels.shift() as string)

export const getAdditionalQuestionsForBooking = (
  bookedSlot?: NonNullable<TicketSettings['slots']>[number],
  booking?: Booking,
) => {
  return bookedSlot?.ticketTypes.reduce<
    {
      fieldsByPerson: (GenericField & {
        value: GenericFieldValue | undefined
        displayValue: string | undefined
      })[][]
      ticketConfig: TicketType
    }[]
  >((result, tt) => {
    const bookedTicket = booking?.tickets?.find(t => t.id === tt.id)
    if (bookedTicket && tt.additionalFields) {
      result.push({
        fieldsByPerson: (bookedTicket.additionalFieldValues || []).map(
          fieldsByPerson => {
            return tt.additionalFields!.map(fieldConfig => {
              const value = fieldsByPerson[fieldConfig.id]
              let displayValue: string | undefined
              switch (fieldConfig.type) {
                case 'text':
                case 'number':
                  displayValue = value?.toString()
                  break
                case 'checkbox':
                  displayValue = value ? 'Ja' : 'Nein'
                  break
                case 'select':
                  displayValue = fieldConfig.options.find(
                    v => v.id === value,
                  )?.label
                  break
              }
              return {
                ...fieldConfig,
                value,
                displayValue,
              }
            })
          },
        ),
        ticketConfig: tt,
      })
    }

    return result
  }, [])
}

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDoiSRYKHdRf1N4BxvQMPW-06tSePd2Sas',
  authDomain: 'bookinstant-dev.firebaseapp.com',
  projectId: 'bookinstant-dev',
  storageBucket: 'bookinstant-dev.appspot.com',
  messagingSenderId: '746317295272',
  appId: '1:746317295272:web:dfba7001ec34c22055dc9d',
  measurementId: 'G-L1W3YK5TXR',
}

export const CONTACT_DATA_FORM_CONFIG = [
  {
    id: 'firstname',
    label: 'Vorname',
    type: 'text',
    required: true,
  },
  {
    id: 'lastname',
    label: 'Nachname',
    type: 'text',
    required: true,
  },
  {
    id: 'birthdate',
    label: 'Geburtsdatum',
    type: 'date',
    required: true,
  },
  {
    id: 'email',
    label: 'E-Mail',
    type: 'email',
    required: true,
  },
  {
    id: 'phone',
    label: 'Telefon',
    type: 'phone',
    required: false,
  },
  {
    id: 'street',
    label: 'Straße und Hausnummer',
    type: 'text',
    required: true,
  },
  {
    id: 'zipCode',
    label: 'PLZ',
    type: 'text',
    required: true,
  },
  {
    id: 'city',
    label: 'Ort',
    type: 'text',
    required: true,
  },
  {
    id: 'country',
    label: 'Land',
    type: 'text',
    required: true,
  },
] as const

export const DayPickerStrings = {
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],

  shortMonths: [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],

  days: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],

  shortDays: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],

  goToToday: 'Heute',
  prevMonthAriaLabel: 'Vorheriger Monat',
  nextMonthAriaLabel: 'Nächster Monat',
  prevYearAriaLabel: 'Vorheriges Jahr',
  nextYearAriaLabel: 'Nächstes Jahr',
}

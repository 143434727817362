import { createContext, useContext, useEffect, useState } from 'react'
import FirebaseContext, { useUser } from './firebase'

const PosSettingsContext = createContext<PosSettings | undefined>(undefined)

export const PosSettingsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const firebase = useContext(FirebaseContext)
  const { authUser } = useUser()

  const [posSettings, setPosSettings] = useState<PosSettings>()

  useEffect(() => {
    if (!authUser) {
      return
    }

    return firebase.subscribeToDocumentData('settings', 'pos', setPosSettings)
  }, [firebase, authUser])

  return (
    <PosSettingsContext.Provider value={posSettings}>
      {children}
    </PosSettingsContext.Provider>
  )
}

export const usePosSettings = () => useContext(PosSettingsContext)

import * as QRCode from 'qrcode'
import { compareAsc, format } from 'date-fns'
import {
  getWeekDay,
  toDecimals,
  toIsoDate,
  toUtcDateHoursZeroed,
} from '../utils'
import { de } from 'date-fns/locale'

export class TicketSettingsUtils {
  ticketSettings: TicketSettings
  slotMap: Map<string, NonNullable<TicketSettings['slots']>[number]>
  contingentTicketTypeMap: Map<
    string,
    NonNullable<TicketSettings['contingentTicketTypes']>[number]
  >
  availableContingentTicketTypes: NonNullable<
    TicketSettings['contingentTicketTypes']
  >
  contingentTicketsByGroup: (Pick<
    NonNullable<TicketSettings['contingentTicketTypeGroups']>[number],
    'id' | 'label'
  > & { contingentTickets: TicketSettings['contingentTicketTypes'] })[]
  contingentTicketTypeGroupMap: Map<
    string,
    NonNullable<TicketSettings['contingentTicketTypeGroups']>[number]
  >

  constructor(ticketSettings: TicketSettings) {
    this.ticketSettings = ticketSettings

    this.slotMap = new Map(
      this.ticketSettings.slots?.map(slot => [slot.id, slot]) || [],
    )
    this.contingentTicketTypeMap = new Map(
      this.ticketSettings.contingentTicketTypes?.map(ctt => [ctt.id, ctt]) ||
        [],
    )

    this.availableContingentTicketTypes =
      this.ticketSettings.contingentTicketTypes?.filter(
        ctt => !ctt.discontinued,
      ) || []

    this.contingentTicketsByGroup =
      this.ticketSettings.contingentTicketTypeGroups?.map(cttg => {
        return {
          id: cttg.id,
          label: cttg.label,
          contingentTickets: this.availableContingentTicketTypes.filter(
            ctt => ctt.groupId === cttg.id,
          ),
        }
      }) || []

    this.contingentTicketTypeGroupMap = new Map(
      this.ticketSettings.contingentTicketTypeGroups?.map(cttg => [
        cttg.id,
        cttg,
      ]) || [],
    )
  }

  getSlot(slotId: string | undefined) {
    return this.slotMap.get(slotId!)
  }

  getContingentTicketType(contingentTicketTypeId: string | undefined) {
    return this.contingentTicketTypeMap.get(contingentTicketTypeId!)
  }

  getContingentTicketTypeGroup(
    contingentTicketTypeGroupId: string | undefined,
  ) {
    return this.contingentTicketTypeGroupMap.get(contingentTicketTypeGroupId!)
  }

  /** Non-exhaustively checks if tickets are available on date */
  dateHasTicketsAvailable(date: Date) {
    const weekDay = getWeekDay(date)

    // If weekday is blocked
    if (this.ticketSettings.blockedWeekdays?.[weekDay]) {
      return false
    }

    // If no slots are available for the weekday
    if (
      !this.ticketSettings.slots?.some(slot => slot.availableWeekdays[weekDay])
    ) {
      return false
    }

    // If date is in a blocked date range
    if (
      this.ticketSettings.blockedDateRanges?.some(blockedRange => {
        const isAfterRangeBegin =
          compareAsc(toUtcDateHoursZeroed(date), blockedRange.from.toDate()) >=
          0
        const isBeforeRangeEnd =
          compareAsc(toUtcDateHoursZeroed(date), blockedRange.to.toDate()) <= 0
        return isAfterRangeBegin && isBeforeRangeEnd
      })
    ) {
      return false
    }

    return true
  }

  /** Gets list of slot IDs that are blocked on date */
  getBlockedSlotIdsForDate(date: Date) {
    const dateZeroed = new Date(date)
    dateZeroed.setHours(0, 0, 0, 0)

    return this.ticketSettings.blockedDates?.find(
      dt => compareAsc(dt.day.toDate(), dateZeroed) === 0,
    )?.slotIds
  }

  /** Gets slot time for date, or default slot time if forDate is null */
  getSlotTime(
    slot: NonNullable<TicketSettings['slots']>[number],
    forDate: Date | null,
  ) {
    if (!forDate) {
      const { from, to } = slot
      return { from, to }
    }

    const forDateIso = toIsoDate(forDate)
    const slotTimeException = this.ticketSettings.slotTimeExceptions?.find(
      exception => {
        const dateMatches = exception.dateIso === forDateIso
        const slotMatches = exception.slotId === slot.id
        return dateMatches && slotMatches
      },
    )

    const forDateWeekDay = getWeekDay(forDate)
    const weekdayTimeException = slot.weekdayTimeExceptions?.find(
      exception => exception.weekday === forDateWeekDay,
    )

    const { from, to } = slotTimeException || weekdayTimeException || slot

    return { from, to }
  }

  /** Gets slot label with time */
  getSlotLabel(
    slot?: string | NonNullable<TicketSettings['slots']>[number],
    forDate?: Date | null,
  ) {
    slot = typeof slot === 'string' ? this.getSlot(slot) : slot
    if (!slot || typeof forDate === 'undefined') {
      return ''
    }

    const { from, to } = this.getSlotTime(slot, forDate)

    const label = slot.label ? slot.label + ' ' : ''
    const time = from + ' - ' + to + ' Uhr'
    return label + time
  }

  /** Gets contingent ticket type label with group name and optionally amount */
  getContingentTicketTypeLabel(
    contingentTicketType:
      | string
      | NonNullable<TicketSettings['contingentTicketTypes']>[number]
      | undefined,
    withAmount?: boolean,
  ) {
    contingentTicketType =
      typeof contingentTicketType === 'string'
        ? this.getContingentTicketType(contingentTicketType)
        : contingentTicketType

    if (!contingentTicketType) {
      return ''
    }

    const ticketTypeGroup = this.getContingentTicketTypeGroup(
      contingentTicketType?.groupId,
    )

    let result = `${ticketTypeGroup?.label || ''} ${
      contingentTicketType?.label || ''
    }`

    if (withAmount) {
      result = `${contingentTicketType?.amount}× ${result}`
    }

    return result
  }

  getBookingPrice(booking: BookingBase) {
    if (!booking.tickets) {
      return 0
    }

    const slot = this.getSlot(booking.slotId)!

    const price = booking.tickets.reduce((aggPrice, nextTicket) => {
      const ticketType = slot.ticketTypes.find(tt => tt.id === nextTicket.id)!
      return aggPrice + nextTicket.amount * ticketType.price
    }, 0)

    return price
  }

  /** Returns the ticket description in the format: "2× Erwachsene à 5,00€, 1× Kind à 2,50€" */
  getTicketsDescription(booking: BookingBase | undefined) {
    if (!booking?.tickets) {
      return ''
    }

    const slot = this.getSlot(booking.slotId)!

    const description = booking.tickets
      .map(ticket => {
        const ticketType = slot.ticketTypes.find(tt => tt.id === ticket.id)!
        return `${ticket.amount}× ${ticketType.label} à ${toDecimals(
          ticketType.price,
        )}€`
      })
      .join(', ')

    return description
  }

  getSingleTicketDetails(
    booking: Booking | undefined,
  ): Array<[string, string]> {
    const bookingDate = booking?.date?.toDate()
    const description = this.getTicketsDescription(booking)

    return [
      ['E-Mail', booking?.email || ''],
      [
        'Datum',
        bookingDate
          ? format(bookingDate, 'EEEE dd. MMMM yyyy', { locale: de })
          : '',
      ],
      ['Badezeit', this.getSlotLabel(booking?.slotId, bookingDate)],
      ['Tickets', description],
    ]
  }

  getContingentTicketDetails(
    contingentTicket: ContingentTicket | undefined,
  ): Array<[string, string]> {
    const contingentTicketType = this.getContingentTicketType(
      contingentTicket?.contingentTicketTypeId,
    )

    return [
      ['E-Mail', contingentTicket?.email || ''],
      [
        'Mehrfachkarte',
        this.getContingentTicketTypeLabel(contingentTicketType, true),
      ],
      [
        'Gültig bis',
        contingentTicket?.validUntil
          ? format(contingentTicket?.validUntil?.toDate(), 'dd. MMMM yyyy', {
              locale: de,
            })
          : '',
      ],
    ]
  }

  async _createQrCode(content: string) {
    return await QRCode.toDataURL(content, {
      width: 200,
    })
  }

  async getSingleTicketQrCode(bookingId: string | undefined | null) {
    if (!bookingId) {
      return ''
    }

    return await this._createQrCode(bookingId)
  }

  async getContingentTicketQrCode(
    contingentTicketId: string | undefined | null,
  ) {
    if (!contingentTicketId) {
      return ''
    }

    return await this._createQrCode(`c:${contingentTicketId}`)
  }
}
